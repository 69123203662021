@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800,700,900,300,100);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{
  height:100vh;
  background-color:#f9f9f9;
}
.left{
  height: 100vh;
  overflow: auto;
}
.left::-webkit-scrollbar-track
{
	background-color: #f9f9f9;
}
.left::-webkit-scrollbar
{
	width: 10px;
	background-color: #f9f9f9;
}
.left::-webkit-scrollbar-thumb
{
	background-color: #f9f9f9;
	border: 2px solid #f9f9f9;
}
.title{
  color:orangered;
  font-weight:bold;
  font-size:30px;
  margin-bottom:20px;
  margin-top:80px;
} 
.sub{
  padding:5px 5px !important;
  margin: 10px;
  border:none;
  color:#fff;
  background-color: #6F8EFF;
  padding:1rem 0;
  width:250px;
  margin-bottom:30px !important;
}
.sub.focus, .sub:focus, .sub:hover {
  color: white !important;
}
.illustration{
  height:45% !important;
  width:35% !important;
  margin-top:20%;
  position:fixed !important;
}
@media only screen and (max-width: 768px) {
  .illustration{
    display:none;
  }
}  
#timeline-wrap{
  margin-top:50px;
  margin-bottom:50px;
  top:100;
  position:relative;
}
#timeline{
  height:1px;
  width: 200px;
  background-color:#6F8EFF;
  position:relative;
  border: 2px solid #6F8EFF;
}
.marker{
  z-index:1000;
  line-height: 50px;
  position: absolute;
}
.m1{
  top:-80px;
}
.m2{
  top:-80px;
  left:200px;
}  
.reg1{
  background-color:green;
  margin-top:65px;
  font-size:13px !important;
  color:white;
}
.reg2{
  background-color:red;
  margin-top:65px;
  font-size:13px !important;
  color:white;
}

.statusin{
  background-color: #6F8EFF !important;
  color: #fff !important;
}
.statusout{
  background-color: #bbbbbb !important;
  color: #fff !important;
}

body{
    height:100vh;
    background-color:#f9f9f9;
  }
  .left{
    height: 100vh;
    overflow: auto;
  }
 /* .stage2content{
   background-color: #ffffff;
   padding: 10px;
   padding-top: 30px;

 } */
  .sub{
    padding:5px 25px !important;
    border:none;
    color:#fff;
    background-color: #F6461D;
    padding:1rem 0;
    width:250px;
    margin-bottom:30px !important;
    margin-top:20px !important;
  }
  .illustration{
    height:50% !important;
    width:40% !important;
    margin-top:150px;
    position:fixed !important;
  }
  @media only screen and (max-width: 768px) {
    .illustration{
      display:none;
    }
  }  
  #timeline-wrap{
    margin-top:50px;
    margin-bottom:50px;
    position:relative;
    margin-top:100px;
  }
  #timeline2{
    height:1px;
    width: 400px;
    background-color:#6F8EFF;
    position:relative;
  }
  .stage2content{
    background-color: #fff;
    padding-top: 10px;
    padding-left: 50px;
  }
  .marker{
    z-index:1000;
    line-height: 50px;
    position: absolute;
  }
  .m1{
    top:-80px;
  }
  .m2{
    top:-80px;
    left:200px;
  } 
  .m3{
    top:-80px;
    left:400px;
  } 
  .btnactive{
    background-color:#6F8EFF;
    margin-top:65px;
    font-size:13px !important;
    color:white;
  }
  .tabs{
    color: black;
  }
  .countlabelbtn{
    width: 30px;
    height: 30px;
    font-size: 10px;
    background-color: #6F8EFF;
    color: #fff;
    margin-left: 2%;
    text-align: center;
  }
  .btnnotactive{
    background-color:gray;
    margin-top:65px;
    font-size:13px !important;
    color:white;
  }
  .form{
      background-color: white;
  }
  .title{
      font-weight:bold;
      margin-top:100px;
      margin-bottom:30px;
  }
  .rolescontent{
    font-weight:bold;
    margin-top:50px;
    margin-bottom:20px;
    line-height: 150%;
  }
  .tips{
      background-color: rgb(82, 82, 165);
      color:white;
      padding:20px;
  }
.ContentDept{
  color: #222;
  font-size: 18px;
  font-weight: 500;
}
.bloc-tabs {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  padding-right:20px ;
  margin-top: 30px;
  
}
.bloc-tabs::-webkit-scrollbar-track
{
	background-color: #ffffff;
}
.bloc-tabs::-webkit-scrollbar
{
	width: 10px;
	background-color: #ffffff;
}
.bloc-tabs::-webkit-scrollbar-thumb
{
	background-color: #ffffff;
	border: 2px solid #ffffff;
}
.tabbed{
  line-height: 1.5;
}
.tabs {

  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  margin: 2%;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: #6F8EFF;
  border-bottom: 1px solid transparent;
  color: #ffffff;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  /* background: #6F8EFF; */
  
}



button {
  border: none;
  
}
.content-tabs {
  flex-grow : 1;
}
.tabcontent {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}
.ReactCollapse--collapse {
    transition: height 500ms;
  }
.app{margin:0 auto;font-size:1.6rem;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:3rem 1rem 1rem;color:#ffffff}.app__text{width:100%;color:#ffffff}.app__collapse{width:100%;display:block;position:relative;overflow:hidden;transition:height 300ms cubic-bezier(0.4, 0, 0.2, 1)}.app__collapse--gradient .app__content{border-color:transparent}.app__collapse--gradient:after{pointer-events:none;transition:opacity 300ms;will-change:opacity;opacity:1;content:"";display:block;position:absolute;top:0;left:0;width:100%;height:100%;background:linear-gradient(transparent 50%, white)}.app__collapse--active:after{opacity:0}.app__content{border:1px solid transparent;padding:1rem 0;margin-bottom:3rem;position:relative;transition:300ms}.app__toggle{width:100%;margin:1rem 0;border:1px solid transparent;text-transform:uppercase;padding:1rem;display:flex;justify-content:space-between;align-items:center;border-radius:2px;box-shadow:0 2px 4px #888;background-color:#5252a5;transition:background-color 300ms}.app__toggle--active{background-color:#5252a5}.app__toggle-text{font-size:inherit}.app__button{display:block;max-width:100%;margin:0 auto;padding:1rem}.app__image{width:100%;max-width:100%;height:auto;display:block}.icon{width:1em;height:1em;transition:-webkit-transform 300ms cubic-bezier(0, 1, 0, 1);transition:transform 300ms cubic-bezier(0, 1, 0, 1);transition:transform 300ms cubic-bezier(0, 1, 0, 1), -webkit-transform 300ms cubic-bezier(0, 1, 0, 1);will-change:transform}.icon--expanded{-webkit-transform:rotateZ(-179.99deg);transform:rotateZ(-179.99deg)}.rotate90{-webkit-transform:rotateZ(90deg);transform:rotateZ(90deg)}

.Contact{height:100vh;display:flex;flex-wrap:wrap}.container .myform{margin-top:100px;float:right;flex:50% 1}.container .mysvg{margin-top:150px;flex:50% 1;float:left;height:43rem;margin-right:4rem}@media (max-width: 800px){.myform,.mysvg{flex:100% 1}}#envelope{-webkit-animation:float 2s ease-in-out infinite;animation:float 2s ease-in-out infinite}.mylabel{font-size:24px}.fa{padding:10px;font-size:28px;width:50px;text-align:center;text-decoration:none;margin:5px 8px;border-radius:50%}.wrap a{text-decoration:none}.fa:hover{opacity:0.7;background-color:antiquewhite}.fa-facebook{background:#3b5998;color:white;text-decoration:none}.fa-google{background:#dd4b39;color:white}.fa-linkedin{background:#007bb5;color:white}.fa-instagram{background:#125688;color:white}

.logo{
    border-radius:50%;
    height:80px;
    width:80px;
    margin-top:-20px;
}
.tag{
    font-size:18px;
    color:black;
}
.navbar{
    border:1px transparent;
}

.logoutbtn{
    background-color: #f9f9f9;
}
.headerbtn:hover {
    border: black 1px solid;
}
body{
    background-color: #f9f9f9;
    /* overflow: hidden; */
}

.content{
    margin-top:150px;
}
.title{
    color:orangered;
    font-weight:bold;
    font-size:40px;
}
.subhome{
    margin-top:50px;
    font-size:28px;
    font-weight:500;
}
.subcontent{
    margin-top:40px;
    font-size:25px;
}
.reg{
    background-color:orangered;
    color:white;
    margin-top:50px;
    padding:15px !important;
    font-size:18px !important;
}
.illustration{
    height:100%;
    width:100%;
    margin-top:50px;
    position: relative;
}
@media only screen and (max-width: 768px) {
    .illustration{
        display:none;
    }
}
@media only screen and (max-width: 600px) {
    .illustration{
        display:none;
    }
    .title{
        color:orangered;
        font-weight:bold;
        font-size:40px;
    }
    .subhome{
        margin-top:50px;
        font-size:28px;
        font-weight:500;
    }
    .subcontent{
        margin-top:40px;
        font-size:25px;
    }
}
.notfound {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.c1 {
  -webkit-animation: mymove 2s linear infinite;
          animation: mymove 2s linear infinite;
}

@-webkit-keyframes mymove {
  25% {
    -webkit-transform: rotateY(5deg);
            transform: rotateY(5deg);
  }
  50% {
    -webkit-transform: rotateX(15deg);
            transform: rotateX(15deg);
  }
  75% {
    -webkit-transform: rotateY(-5deg);
            transform: rotateY(-5deg);
  }
}

@keyframes mymove {
  25% {
    -webkit-transform: rotateY(5deg);
            transform: rotateY(5deg);
  }
  50% {
    -webkit-transform: rotateX(15deg);
            transform: rotateX(15deg);
  }
  75% {
    -webkit-transform: rotateY(-5deg);
            transform: rotateY(-5deg);
  }
}

