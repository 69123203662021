.logo{
    border-radius:50%;
    height:80px;
    width:80px;
    margin-top:-20px;
}
.tag{
    font-size:18px;
    color:black;
}
.navbar{
    border:1px transparent;
}

.logoutbtn{
    background-color: #f9f9f9;
}
.headerbtn:hover {
    border: black 1px solid;
}