.app {
  margin: 0 auto;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   max-width: 500px;
  padding: 3rem 1rem 1rem;
  color: #ffffff;

  &__text {
    width: 100%;
    color: #ffffff;
  }

  &__collapse {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &--gradient {
      .app__content {
        border-color: transparent;
        // background-color: #4fcdff;
      }

      &:after {
        pointer-events: none;
        transition: opacity 300ms;
        will-change: opacity;
        opacity: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 50%, white);
      }
    }

    &--active {
      &:after {
        opacity: 0;
      }
    }
  }

  &__content {
    border: 1px solid transparent;
    padding: 1rem 0;
    margin-bottom: 3rem;
    position: relative;
    transition: 300ms;
  }

  &__toggle {
    width: 100%;
    margin: 1rem 0;
    border: 1px solid transparent;
    text-transform: uppercase;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    box-shadow: 0 2px 4px #888;
    background-color: #5252a5;
    transition: background-color 300ms;
    &--active {
      background-color: #5252a5;
    }
  }

  &__toggle-text {
    font-size: inherit;
  }

  &__button {
    display: block;
    // width: 20rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.icon {
  width: 1em;
  height: 1em;
  transition: transform 300ms cubic-bezier(0, 1, 0, 1);
  will-change: transform;
  &--expanded {
    transform: rotateZ(-179.99deg);
  }
}

.rotate90 {
  transform: rotateZ(90deg);
}
