body{
  height:100vh;
  background-color:#f9f9f9;
}
.left{
  height: 100vh;
  overflow: auto;
}
.left::-webkit-scrollbar-track
{
	background-color: #f9f9f9;
}
.left::-webkit-scrollbar
{
	width: 10px;
	background-color: #f9f9f9;
}
.left::-webkit-scrollbar-thumb
{
	background-color: #f9f9f9;
	border: 2px solid #f9f9f9;
}
.title{
  color:orangered;
  font-weight:bold;
  font-size:30px;
  margin-bottom:20px;
  margin-top:80px;
} 
.sub{
  padding:5px 5px !important;
  margin: 10px;
  border:none;
  color:#fff;
  background-color: #6F8EFF;
  padding:1rem 0;
  width:250px;
  margin-bottom:30px !important;
}
.sub.focus, .sub:focus, .sub:hover {
  color: white !important;
}
.illustration{
  height:45% !important;
  width:35% !important;
  margin-top:20%;
  position:fixed !important;
}
@media only screen and (max-width: 768px) {
  .illustration{
    display:none;
  }
}  
#timeline-wrap{
  margin-top:50px;
  margin-bottom:50px;
  top:100;
  position:relative;
}
#timeline{
  height:1px;
  width: 200px;
  background-color:#6F8EFF;
  position:relative;
  border: 2px solid #6F8EFF;
}
.marker{
  z-index:1000;
  line-height: 50px;
  position: absolute;
}
.m1{
  top:-80px;
}
.m2{
  top:-80px;
  left:200px;
}  
.reg1{
  background-color:green;
  margin-top:65px;
  font-size:13px !important;
  color:white;
}
.reg2{
  background-color:red;
  margin-top:65px;
  font-size:13px !important;
  color:white;
}

.statusin{
  background-color: #6F8EFF !important;
  color: #fff !important;
}
.statusout{
  background-color: #bbbbbb !important;
  color: #fff !important;
}
